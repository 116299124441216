export const OrderStatus = {
	NEW: 1,
	ORDERED: 2,
	CONFIRMED: 3,
	PICKED_UP: 4,
}

export const OrderBadgeEnum = {
	ordered: 'red',
	confirmed: 'orange',
	picked_up: 'green',
}

export const OrderStatusEnum = {
	ordered: '確認待ち',
	confirmed: '準備中',
	picked_up: '準備完了',
}
