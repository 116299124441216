import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Stack, Box, Flex, Circle, Badge } from '@chakra-ui/react'
import { format } from 'date-fns'
import { axios } from '../lib/axios-client'
import { useAuth } from '../context/auth'
import { OrderGroup } from '../types/order_group'
import { OrderBadgeEnum, OrderStatusEnum } from '../constants/order'

export default function Index() {
	const { isAuthenticated } = useAuth()
	const [isLoading, setIsLoading] = useState(false)
	const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([])

	useEffect(() => {
		setIsLoading(true)
		const fetchOrderGroups = async () => {
			const res = await axios.get(`${process.env.REST_ENDPOINT}/driver/api/v1/order_groups`)
			setOrderGroups(res.data)
			setIsLoading(false)
		}

		isAuthenticated && fetchOrderGroups()
	}, [isAuthenticated])

	return (
		<Box pt={16}>
			{isLoading ? (
				<Box p={4}>...loading</Box>
			) : orderGroups.length > 0 ? (
				<Stack p={6} spacing={4}>
					{orderGroups.map((orderGroup, index) => (
						<Link key={index} href={`${orderGroup.id}`}>
							<Box shadow="lg" rounded="lg" fontWeight="bold">
								<Box p={4} borderBottom="1px" borderColor="gray.200">
									#{orderGroup.id}
								</Box>
								<Box p={4}>
									<Stack spacing={4}>
										{orderGroup.orders.map((order, index) => (
											<Flex key={index} alignItems="center">
												<Box mr={4}>
													<Circle size="60px" bg="teal.400" color="white">
														{format(new Date(order.pickup_at), 'HH:mm')}
													</Circle>
												</Box>
												<Box width="full">
													<Flex alignItems="center" justifyContent="space-between">
														<Box>{order.shop.title}</Box>
														<Badge
															fontSize="md"
															colorScheme={OrderBadgeEnum[order.status]}
															variant="outline"
														>
															{OrderStatusEnum[order.status]}
														</Badge>
													</Flex>
													<Box color="gray" fontWeight="normal" flex="1">
														{order.shop.address}
													</Box>
												</Box>
											</Flex>
										))}
										<Flex alignItems="center">
											<Box mr={4}>
												<Circle size="60px" bg="gray.400" color="white">
													{format(new Date(orderGroup.deliver_at), 'HH:mm')}
												</Circle>
											</Box>
											<Box>
												<Box>{orderGroup.name}様</Box>
												<Box color="gray" fontWeight="normal">
													{orderGroup.city}
													{orderGroup.street_name}
												</Box>
											</Box>
										</Flex>
									</Stack>
								</Box>
							</Box>
						</Link>
					))}
				</Stack>
			) : (
				<Box py={24} textAlign="center">
					配達はありません
				</Box>
			)}
		</Box>
	)
}
